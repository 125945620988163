import styled from 'styled-components';
import { CloseIcon } from '../assets';

const NotificationWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	background: ${props =>
		props.type === 'error'
			? 'rgba(240, 80, 80, 0.2)'
			: props.type === 'success'
			? 'rgba(113, 221, 55, 0.3)'
			: 'rgba(226, 175, 206, 0.8)'};
	border-radius: 4px;
	padding: 12px 16px;
	margin: 0 32px;
	user-select: none;
	font-size: 12px;
	line-height: 1.5;
	color: black;
	transition: all 4s ease-in-out;
`;

const CloseButton = styled.div`
	cursor: pointer;
	:active {
		transform: scale(1.1);
	}
`;

export const Notification = ({ type, message, visible = true, onClose }) => {
	return (
		visible && (
			<NotificationWrapper type={type}>
				{message}
				{onClose && (
					<CloseButton onClick={onClose}>
						<img src={CloseIcon} alt="" />
					</CloseButton>
				)}
			</NotificationWrapper>
		)
	);
};
