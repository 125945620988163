import styled from 'styled-components';
import { ButtonBase, LabelWrapper } from './base';

const ValueWrapper = styled.div`
	border-radius: 5px;
	padding: 12px 16px;
	background: white;
	color: black;
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 12px;
`;

const InputContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: 16px;
	align-items: center;
`;

export const ValueDisplay = ({ label, value }) => {
	return (
		<Wrapper>
			<LabelWrapper>{label}</LabelWrapper>
			<ValueWrapper>{value.toFixed(5)}</ValueWrapper>
		</Wrapper>
	);
};

const InputWrapper = styled.div`
	position: relative;
	display: flex;
	flex-grow: 1;
`;

const MaxButton = styled(ButtonBase)`
	background: white;
	padding: 12px 24px;
	color: black;
	border-radius: 5px;
	cursor: pointer;
`;

const NumberInput = styled.input`
	position: relative;
	background: white;
	border: 1px solid #b8c9c9;
	color: #899a9a;
	padding: 12px 16px;
	border-radius: 5px;
	width: 100%;
	:focus {
		outline: none;
	}
	::-webkit-outer-spin-button,
	::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
`;

const UnitWrapper = styled.div`
	position: absolute;
	top: 50%;
	right: 12px;
	transform: translateY(-50%);
	color: #899a9a;
`;

export const ValueInput = ({ label = 'Amount', unit = 'TAO', onMax, ...props }) => {
	return (
		<Wrapper>
			<LabelWrapper>{label}</LabelWrapper>
			<InputContainer>
				<MaxButton onClick={onMax}>Use Max</MaxButton>
				<InputWrapper>
					<NumberInput type='number' placeholder='Input amount' {...props} />
					<UnitWrapper>{unit}</UnitWrapper>
				</InputWrapper>
			</InputContainer>
		</Wrapper>
	);
};
