import "./App.css";
import { Banner } from "./assets";
import { StakingModal } from "./components/StakingModal";
import { navLinks, VIDEO_BG_URL } from "./utils/config";
import { ApiContextProvider } from "./contexts";

function App() {
  return (
    <ApiContextProvider>
      <div className="app-container">
        <div className="video-container">
          <video
            autoPlay
            muted
            loop
            className="video-bg"
            width="100%"
            height="100%"
          >
            <source src={VIDEO_BG_URL} type="video/mp4" />
          </video>
        </div>
        <header className="header-container">
          <img src={Banner} alt="" className="banner-image" />
          <div className="nav-container">
            <nav className="nav-links">
              {navLinks.map(({ label, link }, index) => (
                <a href={link} key={index}>
                  {label}
                </a>
              ))}
            </nav>
          </div>
        </header>
        <main className="main-container">
          <StakingModal />
        </main>
        <footer className="copyright-info">
          © 2024 TAO-Validator.com - All rights reserved.
        </footer>
      </div>
    </ApiContextProvider>
  );
}

export default App;
