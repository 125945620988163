import styled from 'styled-components';

export const ButtonBase = styled.button`
	cursor: pointer;
	border: none;
	:active {
		transform: scale(1.02);
	}
`;

export const LabelWrapper = styled.div`
	line-height: 1.6;
	font-weight: 600;
	color: white;
`;

export const ContainerBase = styled.div`
	display: flex;
	flex-direction: column;
	background: white;
	border-radius: 5px;
	color: black;
`;
