export const VIDEO_BG_URL = 'https://tao-validator.com/wp-content/uploads/2021/01/revolt-1440-dark.mp4';
export const BITTENSOR_SOCKET_URL = 'wss://entrypoint-finney.opentensor.ai:443';
export const APP_NAME = 'StakeTAO';
export const HOTKEY = '5Fy3MjrdKRvUWSuJa4Yd5dmBYunzKNmXnLcvP22NfaTvhQCY';
export const UNIT = 1000000000; // TODO: should remove this hard-coded constant
export const navLinks = [
	{
		label: 'Welcome',
		link: 'https://tao-validator.com/'
	},
	// {
	// 	label: 'Stake Tao',
	// 	link: 'https://tao-validator.com/staking/'
	// },
	{
		label: 'Loyalty',
		link: 'https://tao-validator.com/loyalty/'
	},
	{
		label: 'Resources',
		link: 'https://tao-validator.com/resources/'
	},
	{
		label: 'Contact',
		link: 'https://tao-validator.com/contact/'
	}
];
export const getDelegates = () => {
	const delegates = require('./delegates.json');
	return Object.entries(delegates)
		.filter(([key]) => key !== HOTKEY)
		.map(([hotkey, info]) => ({
			hotkey,
			name: info.name
		}));
};
