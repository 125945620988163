import styled from 'styled-components';
import { LeftArrow } from '../assets';
import { ButtonBase } from './base';

export const Button = styled(ButtonBase)`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 12px 20px;
	background: linear-gradient(to right, #e2afce 6%, #e5ddaa 95%);
	border-radius: 8px;
	font-weight: 600;
	color: white;
	:disabled {
		color: rgba(0, 0, 0, 0.2);
		:active {
			transform: none;
		}
	}
`;

const BackButtonWrapper = styled.div`
	display: flex;
	flex-direction: row;
	gap: 8px;
	color: white;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	:active {
		transform: scale(1.02);
	}
`;

export const BackButton = props => {
	const handleClick = () => {
		if (props.disabled) return;
		if (props.onClick) props.onClick();
	};
	return (
		<BackButtonWrapper onClick={handleClick}>
			<img src={LeftArrow} alt="" />
			Go back
		</BackButtonWrapper>
	);
};
