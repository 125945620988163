import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  BackButton,
  Button,
  Modal,
  ModalTitle,
  Notification,
  ValueDisplay,
  ValueInput,
} from "../../elements";
import { useApi } from "../../contexts";
import { getDelegates, HOTKEY, UNIT } from "../../utils/config";
import { web3FromSource } from "@polkadot/extension-dapp";
import { ContainerBase } from "../../elements/base";

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StakingModalWrapper = styled(Modal)`
  @media (min-width: 560px) {
    max-width: 560px;
    min-width: 560px;
  }
  @media (max-width: 559px) {
    max-width: 300px;
    min-width: 300px;
  }
`;

const AccountsList = styled.select`
  padding: 12px;
  border-radius: 5px;
`;

const Account = styled.option``;

const BalancesWrapper = styled(ContainerBase)`
  padding: 6px;
`;

const BalancesTitle = styled.h1`
  color: black;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  padding: 16px 0;
  margin: 0;
`;

const Separator = styled.hr`
  width: 100%;
  color: black;
  margin: 0;
`;

const DelegateBalanceItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
`;

const DelegateInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const DelegateName = styled.p`
  font-size: 12px;
  line-height: 1.2;
`;

const DelegateAddress = styled.p`
  font-size: 10px;
  line-height: 1;
  color: rgba(0, 0, 0, 0.6);
`;

const UnstakeDelegateButton = styled(Button)`
  flex-wrap: wrap;
  width: 120px;
  height: 56px;
`;

export const StakingModal = () => {
  const [stakedAmount, setStackedAmount] = useState(0);
  const [balance, setBalance] = useState(0);
  const [amount, setAmount] = useState(0);
  const [delegateBalances, setDelegateBalances] = useState([]);
  const [page, setPage] = useState("info");
  const [accounts, setAccounts] = useState([]);
  const [txStatus, setTxStatus] = useState({});
  const [pending, setPending] = useState(false);
  const delegates = getDelegates();

  const {
    connectWallet,
    setCurrentAccount,
    state: { api, currentAccount, keyring },
  } = useApi();

  const fetchStakedAmount = async (hotkey, address) => {
    if (!api) return 0;
    const res = await api.query.subtensorModule.stake(hotkey, address);
    if (res.isEmpty) return 0;
    else {
      const amount = res.toJSON() / UNIT;
      return amount;
    }
  };

  const fetchAndSetStakedAmount = async () => {
    const amount = await fetchStakedAmount(HOTKEY, currentAccount.address);
    setStackedAmount(amount);
  };

  const fetchUserBalance = async (address) => {
    const res = await api.query.system.account(address);
    if (res.isEmpty) return 0;
    const { data } = res.toJSON();
    return data.free / UNIT;
  };

  const fetchAndSetUserBalance = async () => {
    const _balance = await fetchUserBalance(currentAccount.address);
    setBalance(_balance);
  };

  const fetchStakeFromDelegates = async (delegates, address) => {
    const staked = [];
    for await (const delegate of delegates) {
      const { hotkey, name } = delegate;
      const amount = await fetchStakedAmount(hotkey, address);
      if (amount === 0) continue;
      staked.push({
        name,
        hotkey,
        amount,
      });
    }
    return staked;
  };

  const fetchAndSetBalances = async () => {
    const data = await fetchStakeFromDelegates(
      delegates,
      currentAccount.address
    );
    setDelegateBalances(data);
  };

  const fetchInfo = () => {
    fetchAndSetStakedAmount();
    fetchAndSetUserBalance();
    fetchAndSetBalances();
  };

  const getFromAccount = async () => {
    const {
      address,
      meta: { source, isInjected },
    } = currentAccount;

    if (!isInjected) {
      return [currentAccount];
    }

    // currentAccount is injected from polkadot-JS extension, need to return the addr and signer object.
    // ref: https://polkadot.js.org/docs/extension/cookbook#sign-and-send-a-transaction
    const injector = await web3FromSource(source);
    return [address, { signer: injector.signer }];
  };

  const txResHandler = ({ status }) => {
    setTxStatus({
      visible: true,
      ...(status.isFinalized
        ? { type: "success", message: "Success" }
        : {
            type: "info",
            message: `Current transaction status: ${status.type}`,
          }),
    });
    if (status.isFinalized) fetchInfo();
  };

  const txErrHandler = (err) => {
    setTxStatus({
      visible: true,
      type: "error",
      message: `😞 Transaction Failed: ${err.toString()}`,
    });
  };

  const submitTx = async (tx) => {
    setPending(true);
    const [address, options] = await getFromAccount();
    tx.signAndSend(
      address,
      { ...options, withSignedTransaction: true },
      txResHandler
    )
      .catch(txErrHandler)
      .finally(() => setPending(false));
  };

  const onStake = () => {
    submitTx(
      api.tx.subtensorModule.addStake(HOTKEY, Math.floor(amount * UNIT))
    );
  };

  const onUnstake = () => {
    submitTx(
      api.tx.subtensorModule.removeStake(HOTKEY, Math.floor(amount * UNIT))
    );
  };

  const onChangeAccount = (account) => {
    setCurrentAccount(keyring.getPair(account));
    initStates();
  };

  const initStates = () => {
    setStackedAmount(0);
    setBalance(0);
    setAmount(0);
    setTxStatus({});
    setPending(false);
    setDelegateBalances([]);

    fetchInfo();
  };

  useEffect(() => {
    if (!keyring) return;
    const _accounts = keyring.getPairs().map((account) => ({
      key: account.address,
      value: account.address,
      text: account.meta.name.toUpperCase(),
    }));
    setAccounts(_accounts);
  }, [keyring]);

  useEffect(() => {
    if (!currentAccount && keyring && accounts.length) {
      setCurrentAccount(keyring.getPair(accounts[0].value));
    }
  }, [currentAccount, accounts, keyring]);

  useEffect(() => {
    if (!currentAccount || !api) return;
    fetchInfo();
  }, [currentAccount, api]);

  return (
    <StakingModalWrapper>
      <ModalTitle>Stake your TAO</ModalTitle>
      <ModalContent>
        {currentAccount ? (
          <>
            {txStatus.type && (
              <Notification
                type={txStatus.type}
                message={txStatus.message}
                visible={txStatus.visible}
                onClose={() => setTxStatus({ ...txStatus, visible: false })}
              />
            )}
            {page === "info" ? (
              <>
                <AccountsList
                  onChange={(e) => onChangeAccount(e.target.value)}
                  value={currentAccount.address}
                >
                  {accounts.map(({ text, value }, index) => (
                    <Account key={index} value={value}>
                      {text}
                    </Account>
                  ))}
                </AccountsList>
                <ValueDisplay label="Wallet Balance" value={balance} />
                <ValueDisplay label="Staked Amount" value={stakedAmount} />
                <ButtonContainer>
                  <Button
                    disabled={balance === 0}
                    onClick={() => setPage("stake")}
                  >
                    Stake
                  </Button>
                  <Button
                    disabled={stakedAmount === 0}
                    onClick={() => setPage("unstake")}
                  >
                    Unstake
                  </Button>
                </ButtonContainer>
                {delegateBalances.length > 0 && (
                  <BalancesWrapper>
                    <BalancesTitle>Balance on All Validators</BalancesTitle>
                    <Separator />
                    {delegateBalances.map(({ name, hotkey, amount }, index) => (
                      <DelegateBalanceItem key={index}>
                        <DelegateInfo>
                          <DelegateName>{name}</DelegateName>
                          <DelegateAddress>{hotkey}</DelegateAddress>
                        </DelegateInfo>
                        <UnstakeDelegateButton
                          onClick={() =>
                            submitTx(
                              api.tx.subtensorModule.removeStake(
                                hotkey,
                                Math.floor(amount * UNIT)
                              )
                            )
                          }
                        >{`Unstake ${amount.toFixed(
                          5
                        )}`}</UnstakeDelegateButton>
                      </DelegateBalanceItem>
                    ))}
                  </BalancesWrapper>
                )}
              </>
            ) : (
              <>
                <ValueDisplay label="Staked Amount" value={stakedAmount} />
                <ValueInput
                  label="Amount"
                  unit="TAO"
                  value={amount || "0"}
                  onChange={(e) => setAmount(parseFloat(e.target.value))}
                  onMax={() => {
                    setAmount(
                      page === "stake" ? balance - 0.0001 : stakedAmount
                    );
                  }}
                />

                <ButtonContainer>
                  {page === "stake" && (
                    <Button
                      disabled={pending || !amount || amount > balance}
                      onClick={onStake}
                    >
                      Stake
                    </Button>
                  )}
                  {page === "unstake" && (
                    <Button
                      disabled={pending || !amount || amount > stakedAmount}
                      onClick={onUnstake}
                    >
                      Unstake
                    </Button>
                  )}

                  <BackButton
                    onClick={() => {
                      setPage("info");
                      initStates();
                    }}
                    disabled={pending}
                  />
                </ButtonContainer>
              </>
            )}
          </>
        ) : (
          <>
            <Notification type="info" message="Please connect your wallet" />
            <Button onClick={connectWallet}>Connect Wallet</Button>
          </>
        )}
      </ModalContent>
    </StakingModalWrapper>
  );
};
