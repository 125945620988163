import styled from 'styled-components';

export const Modal = styled.div`
	display: flex;
	flex-direction: column;
	background: rgba(255, 255, 255, 0.1);
	border-radius: 8px;
	padding: 48px 32px;
`;

export const ModalTitle = styled.h1`
	font-size: 28px;
	line-height: 1.6;
	font-weight: 700;
	text-align: center;
`;
